
<template>
  <div id="page-user-rdit">
    <vx-card >
      <div slot="no-body" class="tabs-container px-6 pt-6">
        <vs-tabs class="tab-action-btn-fill-conatiner">
          <vs-tab label="Account" icon-pack="feather" icon="icon-user">
            <vs-alert color="success" class="mb-4" v-if="is_submitted" title="Success" active="true">
              The new user was created successfully!!
            </vs-alert>
            <div class="tab-text">
              <form>
                <div class="vx-row">
                  <div class="vx-col md:w-1/2 w-full">
                    <vs-input class="w-full mt-4" label="First Name" v-model="data.first_name"
                      v-validate="'required|alpha_spaces'" placeholder="first name" name="first_name" />
                    <span class="text-danger text-sm"  v-show="errors.has('first_name')">{{ errors.first('first_name') }}</span>
                  </div>
                  <div class="vx-col md:w-1/2 w-full">
                    <vs-input class="w-full mt-4" label="Last Name"
                      v-model="data.last_name" v-validate="'required|alpha_spaces'"
                      placeholder="last name" name="last_name" />
                    <span class="text-danger text-sm"  v-show="errors.has('last_name')">{{ errors.first('last_name') }}</span>
                  </div>
                  <div class="vx-col md:w-1/2 w-full">
                    <vs-input class="w-full mt-4" label="Username" v-model="data.username"
                      v-validate="{regex: '^\\+?([a-z0-9]+)$' }" placeholder="username" name="username" />
                    <span class="text-danger text-sm" v-show="errors.has('username')">{{ errors.first('username') }}</span>
                  </div>
                  <div class="vx-col md:w-1/2 w-full">
                    <vs-input class="w-full mt-4" placeholder="email" label="Email"
                      v-model="data.email" type="email" v-validate="'required|email'" name="email" />
                      <span class="text-danger text-sm"  v-show="errors.has('email')">{{ errors.first('email') }}</span>
                  </div>
                  <div class="vx-col md:w-1/2 w-full">
                    <vs-input class="w-full mt-4" label="Mobile" v-model="data.mobile"
                      v-validate="{regex: '^\\+?([0-9]+)$' }" placeholder="mobile" name="mobile" />
                      <span class="text-danger text-sm"  v-show="errors.has('mobile')">{{ errors.first('mobile') }}</span>
                  </div>
                  <div class="vx-col md:w-1/2 w-full">
                    <div class="mt-4">
                       <vs-select autocomplete placeholder="--select role--"  v-validate="'required'"
                          v-model="data.role" name="role" label="Role *" class="select-large w-full">
                          <vs-select-item :key="index" :value="item.name" :text="item.label | capitalize" v-for="(item,index) in roleOptions" />
                      </vs-select>
                       <span class="text-danger text-sm" v-show="errors.has('role')">{{ errors.first('role') }}</span>
                    </div>
                  </div>
                  <div class="vx-col md:w-1/2 w-full">
                    <div class="mt-4">
                      <label class="vs-input--label">Password</label>
                      <vs-input type="password" v-validate="'required|min:4|max:100'" ref="password" placeholder="Enter Password" name="password" v-model="data.password" class=" w-full" />
                      <span class="text-danger text-sm" v-show="errors.has('password')">{{ errors.first('password') }}</span>
                    </div>
                  </div>
                  <div class="vx-col md:w-1/2 w-full">
                    <div class="mt-4">
                      <label class="vs-input--label">Confirm Password</label>
                      <vs-input type="password" v-validate="'required|min:4|max:100|confirmed:password'"
                        data-vv-as="password" placeholder="Confirm Password" name="confirm_password"
                        v-model="data.password_confirmation" class="w-full" />
                      <span class="text-danger text-sm" v-show="errors.has('confirm_password')">{{ errors.first('confirm_password') }}</span>
                    </div>
                  </div>
                  <div class="vx-col md:w-1/2 w-full">
                    <div class="mt-4">
                      <label class="text-sm">Status</label>
                      <div class="mt-2">
                        <vs-radio v-model="data.status" vs-value="active" class="mr-4">Active</vs-radio>
                        <vs-radio v-model="data.status" vs-value="deactivated" class="mr-4">Deactivated</vs-radio>
                        <vs-radio v-model="data.status" vs-value="blocked" class="mr-4">Blocked</vs-radio>
                      </div>
                    </div>
                  </div>
                  <div class="vx-col md:w-1/2 w-full">
                    <div class="mt-4">
                      <label class="text-sm">Verification</label>
                        <div class="mt-2">
                          <vs-switch v-model="data.is_verified">
                            <span slot="on">true</span>
                            <span slot="off">false</span>
                          </vs-switch>
                        </div>
                    </div>
                  </div>
                </div>
                <div class="vx-row">
                  <div class="vx-col w-full">
                    <div class="mt-8 flex flex-wrap items-center justify-end">
                      <vs-button type="filled" @click.prevent="submitForm" class="mt-2 block">Submit</vs-button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </vs-tab>
        </vs-tabs>
      </div>
    </vx-card>
  </div>
</template>

<script type="text/javascript">

export default {
  data() {
    return {
      is_submitted: false,
      data: {
        first_name: "",
        last_name: "",
        username: "",
        email: "",
        mobile: null,
        role: "",
        status: "active",
        password: "",
        is_verified: false,
        password_confirmation: "",
      },
    }
  },
  computed: {
    roleOptions() {
      return this.$store.state.userList.roles.map(role => {
        return {
          name: role.name,
          label: role.name
      }})
    },
  },
  methods: {
    submitForm() {
      this.$validator.validateAll().then(result => {
        if (result) {
          let formData = new FormData();
          formData.append('data', JSON.stringify(this.data))
          this.$vs.loading({ color: "#444",type: "sound" })
          this.$store.dispatch("userList/addNewUser",formData)
          .then((response) => {
            this.$vs.loading.close()
            if(response.data.success){
              this.timerShowAlert()
              this.resetForm()
            }
          })
        }
      })
    },
    resetForm() {
      this.data = {
        first_name: "",
        last_name: "",
        username: "",
        email: "",
        mobile: null,
        role: "",
        status: "active",
        password: "",
        is_verified: false,
        password_confirmation: "",
      }
      this.$validator.reset()
    },
    timerShowAlert(){
      this.is_submitted = true
      setTimeout(() => {
          this.is_submitted = false
      }, 3000);
    }
  },
  created(){
    if(!this.$store.getters['userList/totalRoles']){
      this.$store.dispatch("userList/fetchRoles")
    }
  },
}
</script>
